@use "@angular/material" as mat;
@import "libs/theme/orion-theme";

@mixin shared-theme($orion-theme) {
  // Rewrite slide-toggle new look @use '@material/switch/switch' as mdc-switch;
  $_color-selected-handle: color-primary($orion-theme);
  $_color-unselected-handle: $color-grey5;
  $_color-unselected-track: $color-blk-4;
  $_color-selected-track: color-opacity(color-primary($orion-theme), 0.5);
  $_color-disabled-selected-track: color-opacity(color-primary($orion-theme), 0.5);
  $_color-disabled-unselected-track: color-opacity(color-primary($orion-theme), 0.5);
  $_contrasted-text-color: color-icons($orion-theme);

  thead,
  .maintask_dashed_container_class {
    background: background-color($orion-theme, hover);
  }

  .mat-button-toggle-checked {
    .mat-pseudo-checkbox {
      display: none;
    }
  }

  .mat-mdc-dialog-container {
    --mdc-dialog-container-color: background-color($orion-theme, background) !important;
    --mdc-dialog-supporting-text-size: 18px;
    background: background-color($orion-theme, background) !important;

    .mdc-dialog__content {
      color: $_contrasted-text-color !important;
    }
  }

  .mat-toolbar {
    background: header-color($orion-theme);
    color: $color-white;
  }

  .mat-mdc-slide-toggle {
    .mdc-switch__icons {
      display: none;
    }

    .mdc-switch {
      @include mat.slide-toggle-overrides(
        (
          // handle color
          disabled-selected-handle-color: $_color-selected-handle,
          disabled-unselected-handle-color: $_color-unselected-handle,
          selected-handle-color: $_color-selected-handle,
          selected-focus-handle-color: $_color-selected-handle,
          selected-hover-handle-color: $_color-selected-handle,
          selected-pressed-handle-color: $_color-selected-handle,
          selected-hover-state-layer-color: $_color-selected-handle,
          selected-pressed-state-layer-color: $_color-selected-handle,
          selected-focus-state-layer-color: $_color-selected-handle,
          unselected-handle-color: $_color-unselected-handle,
          unselected-focus-handle-color: $_color-unselected-handle,
          unselected-hover-handle-color: $_color-unselected-handle,
          unselected-pressed-handle-color: $_color-unselected-handle,
          //tracks color
          disabled-selected-track-color: $_color-selected-track,
          disabled-unselected-track-color: $_color-unselected-track,
          selected-track-color: $_color-selected-track,
          selected-focus-track-color: $_color-selected-track,
          selected-hover-track-color: $_color-selected-track,
          selected-pressed-track-color: $_color-selected-track,
          unselected-track-color: $_color-unselected-track,
          unselected-focus-track-color: $_color-unselected-track,
          unselected-hover-track-color: $_color-unselected-track,
          unselected-pressed-track-color: $_color-unselected-track
        )
      );
    }
  }

  .mat-icon,
  .mat-icon.mat-primary {
    color: color-icons($orion-theme) !important;
  }

  button:disabled {
    .mat-icon {
      color: foreground-rgb-color($orion-theme, 0.3) !important;
    }
  }

  .search-admin > input,
  .search-bar,
  .search_container > input {
    background: background-search($orion-theme);
    color: inherit;
  }

  button.mat-primary,
  button.mat-warn {
    color: text-with-background($orion-theme) !important;

    span {
      color: text-with-background($orion-theme) !important;
    }

    &:disabled {
      color: foreground-rgb-color($orion-theme, 0.2) !important;

      span {
        color: foreground-rgb-color($orion-theme, 0.2) !important;
      }
    }
  }

  .basic-button {
    color: color-primary($orion-theme) !important;

    span {
      color: color-primary($orion-theme) !important;
    }
  }

  .mat-badge-content {
    color: text-with-background($orion-theme) !important;
  }

  .mat-mdc-snack-bar-action {
    color: color-link($orion-theme) !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px background-color($orion-theme, background) inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: foreground-color($orion-theme, text) !important;
  }

  // SPEC: Remove focused color on mat-tab
  .mat-mdc-tab-label,
  .mat-mdc-tab-link {
    &.cdk-keyboard-focused,
    &.cdk-program-focused {
      &:not(.mat-mdc-tab-disabled) {
        background-color: transparent !important;
      }
    }
  }

  .mat-calendar-table-header {
    background: background-search($orion-theme) !important;
    color: foreground-color($orion-theme, text) !important;
  }

  ngx-colors-panel .opened {
    background-color: background-search($orion-theme);
    color: foreground-color($orion-theme, text);

    .manual-input-wrapper {
      input,
      p {
        background-color: background-search($orion-theme);
        color: foreground-color($orion-theme, text) !important;
      }
    }

    button {
      border: solid 1px foreground-color($orion-theme, text) !important;
      color: color-primary($orion-theme) !important;
    }

    .round-button {
      fill: color-icons($orion-theme);
    }
  }

  .deteriorate-warning {
    background: $color-warn-light;
    color: $color-warn;
    font-size: 14px;
    padding: 5px;
    text-align: center;
  }

  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    background-color: unset !important;
  }

  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mdc-text-field__input {
    color: unset;
  }

  .mat-mdc-paginator-range-actions,
  .mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: unset;
    --mat-mdc-button-icon-color: unset;
    --mdc-icon-button-icon-color: unset;
    --mat-mdc-button-persistent-ripple-color: unset;
  }

  .mat-mdc-paginator-range-actions {
    .mat-mdc-icon-button {
      padding: 0;
      --mdc-icon-button-state-layer-size: 24px;
      --mdc-icon-button-icon-size: 24px;
    }

    .mat-mdc-icon-button .mat-mdc-paginator-icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }

    .mat-mdc-paginator-range-label {
      margin-right: 10px;
    }

    .mat-mdc-button-touch-target {
      width: 24px;
    }
  }

  .mdc-checkbox__native-control {
    display: none;
  }

  // Remove the automatic focus on the first menu item
  .mdc-list-item:focus::before {
    display: none;
  }

  // Remove the focus that remains after closing a popup opened by clicking on one of the menu buttons
  .mat-mdc-button-persistent-ripple {
    display: none;
  }
}
