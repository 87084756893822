@import "libs/theme/orion-theme";

@mixin msm-als-theme($orion-theme) {
  .task-card {
    .reference,
    .version {
      color: foreground-rgb-color($orion-theme, 0.47);
    }

    .red {
      color: color-red($orion-theme);
    }

    .green {
      color: color-green($orion-theme);
    }

    //SPEC: if there is a MFC, the mpPn row is highlighted in grey to improve readability during hover
    .mpPn-row:has(.mfc):hover {
      background-color: background-color($orion-theme, hover);
    }

    //SPEC: with changeMark background we want applic light theme colors
    .changeMarkClass {
      &:not(.marked-text) {
        background-color: $color-changeMark !important;
      }
    }

    .changeMarkDeletionClass {
      color: $color-blk-87;

      &:not(.marked-text) {
        background-color: $color-changeMark-deletion !important;
      }
    }

    .changeMarkClass,
    .changeMarkDeletionClass {
      .reference,
      .version {
        color: foreground-rgb-color($orion-light-theme, 0.47) !important;
      }

      .green {
        color: color-green($orion-light-theme) !important;
      }

      .red,
      &.red {
        color: color-red($orion-light-theme);
      }

      .key,
      .title {
        color: foreground-rgb-color($orion-light-theme, 0.87);
      }

      o-revision-mark {
        .blue {
          background: color-primary($orion-light-theme) !important;
          color: text-with-background($orion-light-theme) !important;
        }

        .green {
          background-color: color-green($orion-light-theme) !important;
          color: text-with-background($orion-light-theme) !important;
        }

        .red {
          background-color: color-red($orion-light-theme) !important;
          color: text-with-background($orion-light-theme) !important;
        }
      }
    }
  }
}

