@import "libs/theme/orion-theme";

@mixin revision-mark-theme($orion-theme) {
  o-revision-mark {
    .blue {
      background: color-primary($orion-theme) !important;
      color: text-with-background($orion-theme) !important;
    }

    .green {
      background-color: color-green($orion-theme) !important;
      color: text-with-background($orion-theme) !important;
    }

    .grey {
      background-color: color-grey($orion-theme) !important;
      color: text-with-background($orion-theme) !important;
    }

    .red {
      background-color: color-red($orion-theme) !important;
      color: text-with-background($orion-theme) !important;
    }

    .orange {
      background-color: $color-warn;
      color: text-with-background($orion-theme) !important;
    }

    .folder {
      color: foreground-rgb-color($orion-theme, 0.87);
    }

    .note {
      color: foreground-rgb-color($orion-theme, 0.87);
    }
  }
}
