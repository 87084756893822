@import "libs/theme/orion-theme";

@mixin xslt-theme($orion-theme) {
  .TITLE_CLASS,
  .TITLE_LEVEL_3_CLASS,
  .sub_header_table_refdoc_doc,
  .srefman_class,
  .srefloc_class,
  .card_operator_class,
  .span_links .srefman_class,
  .span_links .srefloc_class,
  .link_class {
    color: color-accent($orion-theme);
  }

  p.subch_title_class,
  .msm_title_class,
  p.glossary_title_class {
    color: blk-color($orion-theme, 0.72);
  }

  .subud_header_table_als_col3,
  .mainlimi_body_table_als_col3,
  .sub_header_table_deadline_minSize,
  .sub_header_table_prn_limit,
  .sub_header_table_prn_interval,
  .sub_header_table_prn_type_of_limit,
  .sub_header_table_refdoc_type_of_limit,
  .sub_header_table_refdoc_interval,
  .sub_header_table_refdoc_interval_RESIZED,
  .msm_interv_td_class {
    color: color-red($orion-theme);
  }

  .maintask_container_class,
  .maintask_dashed_container_class {
    background: background-color($orion-theme, hover);
  }

  .pnrlist_table_class > tbody > tr > td:first-child,
  .sub_header_table_prn_mpn > span:first-child,
  .pnrlist_table_class .span_links {
    color: color-green($orion-theme);
  }
}
