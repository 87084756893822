@import "libs/theme/orion-theme";

@mixin ipc-card-theme($orion-theme) {
  o-ipc-card {
    &.selected {
      .mat-mdc-card,
      .in-grid {
        background: background-color($orion-theme, hover);
      }
    }

    .in-grid {
      color: foreground-color($orion-theme, text);
    }

    .ipc--custom {
      color: color-red($orion-theme);
    }

    mat-card,
    .shop-col {
      .mat-badge-content {
        align-items: center;
        background: color-accent($orion-theme) !important;
        bottom: -4px !important;
        display: flex;
        font-size: 14px !important;
        justify-content: center;
        left: 13px !important;
        margin: 0 !important;
        right: -8px !important;
        top: 16px !important;
      }

      .is-in-basket .mat-badge-content {
        background: color-green($orion-theme) !important;
      }
    }
  }
}
