@use "@angular/material" as mat; // Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @import "./orion-dark-theme.scss";
// @import "./orion-light-theme.scss";
@import "orion-palette";

$orion-primary: mat.m2-define-palette($mat-orion, 500);
$orion-dark-primary: mat.m2-define-palette($mat-orion, A800);

$orion-accent: mat.m2-define-palette($mat-orion, 700);
$orion-dark-accent: mat.m2-define-palette($mat-orion, A800);

$orion-secondary: mat.m2-define-palette($mat-orion, 900);

$orion-warn: mat.m2-define-palette($mat-orion, 200);
$orion-err: mat.m2-define-palette($mat-orion, 300);
$orion-dark-err: mat.m2-define-palette($mat-orion, 1000);

$orion-foreground: mat.m2-define-palette($mat-orion, 300);
$orion-green: mat.m2-define-palette($mat-orion, 50);
$orion-yellow: mat.m2-define-palette($mat-orion, 100);
$orion-blue: mat.m2-define-palette($mat-orion, A200);
$orion-black: mat.m2-define-palette($mat-orion, A1000);

$color-accent: mat.m2-get-color-from-palette($orion-accent);
$color-link: mat.m2-get-color-from-palette($orion-accent);
$color-dark-link: mat.m2-get-color-from-palette($orion-dark-primary);

$color-grey0: #cecece;
$color-grey1: #aaa;
$color-grey2: #eee;
$color-grey3: #d4d4d4;
$color-grey4: #666;
$color-grey5: #f5f5f5;

$color-nonApplic: #b4b4b4;

$color-dark-card: #424242;

// change color rgba to hexa
$color-blk-01: #fcfcfc;
$color-blk-05: #f2f2f2;
$color-blk-1: #e5e5e5;
$color-blk-12: #e0e0e0;
$color-blk-14: #dbdbdb;
$color-blk-2: #ccc;
$color-blk-25: #bfbfbf;
$color-blk-3: #b2b2b2;
$color-blk-36: #a3a3a3;
$color-blk-4: #999;
$color-blk-47: #878787;
$color-blk-5: #7f7f7f;
$color-blk-54: #757575;
$color-blk-72: #474747;
$color-blk-8: #333;
$color-blk-87: #212121;

$color-wht-87: #dedede;
$color-wht-8: #ccc;

// other variables
$header-height: 60px;
$nonApplic-height: 40px;
$e-ordering-color: #37c9ea;

$orion-light-theme-basis: mat.m2-define-light-theme((
  color: (
    primary: $orion-primary,
    accent: $orion-accent,
    warn: $orion-err,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
));
$orion-dark-theme-basis: mat.m2-define-dark-theme((
  color: (
    primary: $orion-dark-primary,
    accent: $orion-dark-accent,
    warn: $orion-dark-err,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
));
// use same white background as angular material
$bg-color: #fafafa;
$background: map-get($orion-light-theme-basis, background);
$background: map_merge($background,
(
  background: $bg-color,
  hover: rgba(black, 0.1),
  searchBg: rgba(white, 0.4),
  matOptionFirst: $color-blk-1
));
// we merge our custom white background to the basic light theme
$orion-light-theme: map_merge($orion-light-theme-basis,
(
  background: $background
));

$background: map-get($orion-dark-theme-basis, background);
$background: map_merge($background,
(
  hover: rgba(white, 0.2),
  searchBg: rgba(white, 0.2),
  matOptionFirst: $color-blk-54
));

$orion-dark-theme: map_merge($orion-dark-theme-basis,
(
  background: $background
));

$mat-orion-blk: (
  0.01: #fcfcfc,
  0.05: #f2f2f2,
  0.1: #e5e5e5,
  0.12: #e0e0e0,
  0.14: #dbdbdb,
  0.2: #ccc,
  0.25: #bfbfbf,
  0.3: #b2b2b2,
  0.36: #a3a3a3,
  0.4: #999,
  0.47: #878787,
  0.5: #7f7f7f,
  0.54: #757575,
  0.72: #474747,
  0.8: #333,
  0.87: #212121
);

@function header-color($theme) {
  @if map-get($theme, is-dark) {
    @return $color-blk-87;
  }

  @return $color-primary;
}

//grey

@function foreground-color($theme, $prop) {
  @return map-get(map-get($theme, foreground), $prop);
}

@function background-color($theme, $prop) {
  @return map-get(map-get($theme, background), $prop);
}

@function foreground-rgb-color($theme, $opacity) {
  @if map-get($theme, is-dark) {
    @return rgba(255, 255, 255, $opacity);
  }
  @return rgba(0, 0, 0, $opacity);
}

@function color-theme-opacity($theme, $opacity) {
  @if map-get($theme, is-dark) {
    @return rgba(0, 0, 0, $opacity);
  }
  @return rgba(255, 255, 255, $opacity);
}

@function color-opacity($color, $opacity) {
  @return rgba(red($color), green($color), blue($color), $opacity);
}

@function color-primary($theme) {
  @return mat.get-theme-color($theme, primary);
}

@function color-accent($theme) {
  @return mat.get-theme-color($theme, accent);
}

@function blk-color($theme, $opacity) {
  @if map-get($theme, is-dark) {
    @return rgba(255, 255, 255, $opacity);
  }
  @return map-get($mat-orion-blk, $opacity);
}

@function adapt-color($theme, $color) {
  @if map-get($theme, is-dark) {
    @return invert($color);
  }
  @return $color;
}

@function color-link($theme) {
  @if map-get($theme, is-dark) {
    @return $color-dark-link;
  }
  @return $color-link;
}

@function color-nonApplic($theme) {
  @if map-get($theme, is-dark) {
    @return $color-nonApplic;
  }
  @return $color-nonApplic;
}

@function color-red($theme) {
  @if map-get($theme, is-dark) {
    @return $color-dark-err;
  }
  @return $color-err;
}

@function color-green($theme) {
  @if map-get($theme, is-dark) {
    @return $color-dark-green;
  }
  @return $color-green;
}

@function color-grey($theme) {
  @if map-get($theme, is-dark) {
    @return $color-grey0;
  }
  @return $color-grey1;
}

@function background-header($theme) {
  @if map-get($theme, is-dark) {
    @return $color-dark-card;
  }
  @return $color-primary;
}

@function background-search($theme) {
  @if map-get($theme, is-dark) {
    @return $color-dark-card;
  }
  @return white;
}

@function background-button($theme) {
  @if map-get($theme, is-dark) {
    @return $color-dark-card;
  }
  @return $color-primary;
}

@function color-icons($theme) {
  @if map-get($theme, is-dark) {
    @return rgba(255, 255, 255, 0.87);
  }
  @return void;
}

@function text-with-background($theme) {
  @if map-get($theme, is-dark) {
    @return $color-dark-card;
  }
  @return white;
}

// solid-hover return hover color without transparency
@function solid-hover($theme) {
  @if map-get($theme, is-dark) {
    @return #686868;
  }
  @return #e5e5e5;
}

@function color-choice($theme, $light-color, $dark-color) {
  @if map-get($theme, is-dark) {
    @return $dark-color;
  }
  @return $light-color;
}

$background-color: background-color($orion-light-theme, background);
$color-white: $background-color;
$color-black: foreground-color($orion-light-theme, text);

$color-primary: mat.m2-get-color-from-palette($orion-primary);
$color-primary-tr: rgba(0, 32, 91, 0.3);
$color-accent: mat.m2-get-color-from-palette($orion-accent);
$color-secondary: mat.m2-get-color-from-palette($orion-secondary);
// warn
$color-warn: mat.m2-get-color-from-palette($orion-warn);
$color-warn-light: #fff0dc;
$color-warn-disabled: #898178;
$color-warn-border-disabled: #87461e;
// error
$color-err: mat.m2-get-color-from-palette($orion-err);
$color-err-light: #ffe4e9;
$color-dark-err: #ff8d8d;
$color-err-disabled: #89777a;
$color-err-border-disabled: #740116;
// note
$color-green: mat.m2-get-color-from-palette($orion-green);
$color-dark-green: #30d158;
$color-note: $color-green;
$color-note-light: #e7ffea;
$color-green-tr: rgba(132, 189, 0, 0.41);
$color-note-disabled: #737e74;
$color-note-border-disabled: #5a8b36;
// hotspot
$color-hotspot-fill: #0f0;
$color-hotspot-stroke: #0f0;
$opacity-hotspot-fill: 0.5;
$opacity-hotspot-stroke: 0.5;
$hotspot-stroke-width: 0.3px;

// success
$color-success: $color-green;
$color-success-light: #e7ffea;
//colors
$color-yellow: yellow;
$color-blue: mat.m2-get-color-from-palette($orion-blue);

// objects color
$color-changeMark: #bbffc4;
$color-changeMark-selected: #88cc91;
$color-changeMark-disabled: #536c56;
$color-changeMark-deletion: #ffd9d9;

// transparent
$color-tr: rgba(255, 255, 255, 0);

// White color transparent
$color-wht-tr8: rgba(255, 255, 255, 0.8);

// black color transparent
$color-blk-tr01: foreground-rgb-color($orion-light-theme, 0.01);
$color-blk-tr05: foreground-rgb-color($orion-light-theme, 0.05);
$color-blk-tr1: foreground-rgb-color($orion-light-theme, 0.1);
$color-blk-tr33: foreground-rgb-color($orion-light-theme, 0.33);
$color-blk-tr67: foreground-rgb-color($orion-light-theme, 0.67);
$color-blk-tr12: foreground-rgb-color($orion-light-theme, 0.12);
$color-blk-tr14: foreground-rgb-color($orion-light-theme, 0.14);
$color-blk-tr2: foreground-rgb-color($orion-light-theme, 0.2);
$color-blk-tr25: foreground-rgb-color($orion-light-theme, 0.25);
$color-blk-tr3: foreground-rgb-color($orion-light-theme, 0.3);
$color-blk-tr36: foreground-rgb-color($orion-light-theme, 0.36);
$color-blk-tr4: foreground-rgb-color($orion-light-theme, 0.4);
$color-blk-tr47: foreground-rgb-color($orion-light-theme, 0.47);
$color-blk-tr5: foreground-rgb-color($orion-light-theme, 0.5);
$color-blk-tr54: foreground-rgb-color($orion-light-theme, 0.54);
$color-blk-tr72: foreground-rgb-color($orion-light-theme, 0.72);
$color-blk-tr8: foreground-rgb-color($orion-light-theme, 0.8);
$color-blk-tr87: foreground-rgb-color($orion-light-theme, 0.87);

$foreground-tr01: foreground-rgb-color($orion-light-theme, 0.01);
$foreground-tr05: foreground-rgb-color($orion-light-theme, 0.05);
$foreground-tr1: foreground-rgb-color($orion-light-theme, 0.1);
$foreground-tr12: foreground-rgb-color($orion-light-theme, 0.12);
$foreground-tr14: foreground-rgb-color($orion-light-theme, 0.14);
$foreground-tr2: foreground-rgb-color($orion-light-theme, 0.2);
$foreground-tr25: foreground-rgb-color($orion-light-theme, 0.25);
$foreground-tr3: foreground-rgb-color($orion-light-theme, 0.3);
$foreground-tr36: foreground-rgb-color($orion-light-theme, 0.36);
$foreground-tr4: foreground-rgb-color($orion-light-theme, 0.4);
$foreground-tr47: foreground-rgb-color($orion-light-theme, 0.47);
$foreground-tr5: foreground-rgb-color($orion-light-theme, 0.5);
$foreground-tr54: foreground-rgb-color($orion-light-theme, 0.54);
$foreground-tr72: foreground-rgb-color($orion-light-theme, 0.72);
$foreground-tr8: foreground-rgb-color($orion-light-theme, 0.8);
$foreground-tr87: foreground-rgb-color($orion-light-theme, 0.87);

// disabled
$opacity-disabled: 0.5;
