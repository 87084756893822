@import "libs/theme/orion-theme";

@mixin fulltext-theme($orion-theme) {
  o-fulltext {
    h2,
    .wires-row ::before,
    .equipments-row ::before {
      color: color-primary($orion-theme);
    }

    .fulltext-title {
      color: foreground-rgb-color($orion-theme, 0.87);
    }

    .header_wrapper,
    .mat-mdc-tab-header-pagination,
    .hideThumb {
      background: background-color($orion-theme, background);
    }

    .maintask_container_class {
      background: background-color($orion-theme, hover);
    }

    .title-in-du {
      h3 {
        color: blk-color($orion-theme, 0.47);
      }
    }

    .applic-label {
      color: color-accent($orion-theme);
    }

    .active-draggable {
      .fulltext-content {
        background: background-color($orion-theme, background);
      }
    }

    .more {
      background: color-theme-opacity($orion-theme, 0.6);
      color: color-primary($orion-theme);

      &:hover {
        background: background-color($orion-theme, hover);
      }
    }

    .header {
      .separator,
      .section-title {
        color: color-primary($orion-theme);
      }

      .anchor.active-section {
        border-bottom: 5px solid color-primary($orion-theme);
      }
    }

    .media-sticky {
      &.notApplicable {
        &.media-preprint {
          o-svg {
            background: color-nonApplic($orion-theme) !important;
          }

          .event-container {
            background: color-nonApplic($orion-theme) !important;
          }

          .media {
            &.preprint {
              .mat-icon-button {
                background: color-nonApplic($orion-theme) !important;
              }
            }
          }
        }
      }
    }

    .notApplicable {
      o-mediafirst {
        o-svg {
          background: color-nonApplic($orion-theme) !important;
        }

        .event-container {
          background: color-nonApplic($orion-theme) !important;
        }

        .mediafirst-content {
          .media {
            &.preprint {
              .mat-icon-button {
                background: color-nonApplic($orion-theme) !important;
              }
            }
          }

          .container span {
            color: $color-white;
          }
        }
      }
    }

    .mediafirst-content {
      .media {
        &.preprint {
          svg {
            background: color-nonApplic($orion-theme) !important;
          }

          .mat-icon-button {
            background: color-nonApplic($orion-theme) !important;
          }
        }
      }
    }

    .fault-general {
      background: color-primary($orion-theme);
      color: text-with-background($orion-theme);
    }

    .content .DU_CONTENT *:not(.applic-inline-header):not(.no-border)[applicability_inline] {
      border-left: 3px solid color-primary($orion-theme) !important;
      padding-left: 20px;
    }
  }
}
